import "./MiniApp.scss";
import { useState } from "react";

function MiniApp() {
  const [appPage, setAppPage] = useState("appPage4");

  return (
    <div className="miniApp">
      <div className="miniAppBody">
        {/* page1 */}
        {appPage === "appPage1" && (
          <div className="page1">
            <div className="testPage1">testPage1</div>
          </div>
        )}

        {/* page2 */}
        {appPage === "appPage2" && (
          <div className="page1">
            <div className="testPage2">testPage2</div>
          </div>
        )}

        {/* page3 */}
        {appPage === "appPage3" && (
          <div className="page1">
            <div className="testPage3">testPage3</div>
          </div>
        )}

        {/* page4 */}
        {appPage === "appPage4" && (
          <div className="appPage">
            <div className="appPage4Header">
              <div className="appPageAvatar"></div>
            </div>
          </div>
        )}
      </div>

      {/* app nanigation */}
      <div className="miniAppNav">
        <div
          className="miniAppNavButton"
          id="appPage1"
          onClick={() => setAppPage("appPage1")}
        >
          <div className="miniAppNavIcon"></div>
        </div>
        <div
          className="miniAppNavButton"
          id="appPage2"
          onClick={() => setAppPage("appPage2")}
        >
          <div className="miniAppNavIcon"></div>
        </div>
        <div
          className="miniAppNavButton"
          id="appPage3"
          onClick={() => setAppPage("appPage3")}
        >
          <div className="miniAppNavIcon"></div>
        </div>
        <div
          className="miniAppNavButton"
          id="appPage4"
          onClick={() => setAppPage("appPage4")}
        >
          <div className="miniAppNavIcon"></div>
        </div>
      </div>
    </div>
  );
}

export default MiniApp;
