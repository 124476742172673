import React from "react";
import './Arrow.scss';

function Arrow (props) {
    return (
        <div className="Arrow" id={props.id}>
            <a className="arrowLink" href={props.target}>
                <svg width="68" height="37" viewBox="0 0 68 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector 81" d="M2 2L34 34L66 2" stroke="#333333" stroke-width="4"/>
                </svg>
            </a>
        </div>
    )
}

export default Arrow;