import MiniApp from "../MiniApp/MiniApp";
import MiniSite from "../MiniSite/MiniSite";

import "./Products.scss";

function Products() {
  return (
    <div className="products">
      <section className="productsSection">
        <h2 className="productsSectionTitle">What do we create?</h2>
        <div className="productsContentBox">
          <h3 className="productsTitle">We develop websites</h3>
          <div className="deviceBox">
            <MiniSite />
            <img
              src="/images/imac.png"
              alt="not found"
              className="productsImg"
            />
          </div>
          <p className="productsDescription">
            From simple static landing pages to complex multi-page projects with
            enhanced security and unique functionality. We do not use
            third-party builders or templates but write code from scratch for
            each project individually.
          </p>
          <h3 className="productsTitle">Web applications</h3>
          <div className="deviceBox">
            <MiniSite />
            <img
              src="/images/imac.png"
              alt="not found"
              className="productsImg"
            />
          </div>
          <p className="productsDescription">
            Web applications is a modern trend in internet development, their
            advantages include speed achieved by partial page updates, only
            updating the components that need to be changed upon user action,
            while simple websites reload the entire page. This site is written
            using this technology.
          </p>
          <h3 className="productsTitle productsTitleMobApp">
            Mobile applications
          </h3>
          <div className="deviceBox">
            <img
              src="/images/iphone.webp"
              alt="not found"
              className="productsImg iphone"
            />
            <MiniApp />
          </div>
          <p className="productsDescription">
            Perhaps the main advantage of mobile applications over websites and
            web applications is push notifications. Those same notifications
            that come to the user even on the locked screen of the device.
            Mobile applications also have a number of other advantages, and
            often such a solution is the most appropriate. We develop mobile
            applications for iOS and Android operating systems.
          </p>
          <h3 className="productsTitle productsTitleMobApp">Design</h3>
          <img
            src="/images/compass.png"
            alt="not found"
            className="productsImg iphone"
            style={{
              transform: "rotate(-73deg)",
            }}
          />
          <p className="productsDescription">
            Perhaps the main advantage of mobile applications over websites and
            web applications is push notifications. Those same notifications
            that come to the user even on the locked screen of the device.
            Mobile applications also have a number of other advantages, and
            often such a solution is the most appropriate. We develop mobile
            applications for iOS and Android operating systems.
          </p>
          <h3 className="productsTitle productsTitleMobApp">Creation</h3>
          <img
            src="/images/lamp.avif"
            alt="not found"
            className="productsImg iphone"
          />
          <p className="productsDescription">
            Perhaps the main advantage of mobile applications over websites and
            web applications is push notifications. Those same notifications
            that come to the user even on the locked screen of the device.
            Mobile applications also have a number of other advantages, and
            often such a solution is the most appropriate. We develop mobile
            applications for iOS and Android operating systems.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Products;
