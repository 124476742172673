import { useState } from "react";
import "./MiniSite.scss";

function MiniSite() {
  const [page, setPage] = useState("miniHome");

  return (
    <div className="miniSite">
      <div className="miniHeader">
        <div className="miniNav">
          <div
            className="miniNavLink"
            id="miniHome"
            onClick={() => setPage("miniHome")}
          ></div>
          <div
            className="miniNavLink"
            id="miniAbout"
            onClick={() => setPage("miniAbout")}
          ></div>
        </div>
        <div className="miniLogo" onClick={() => setPage("miniHome")}></div>
        <div className="miniNav">
          <div
            className="miniNavLink"
            id="miniProducts"
            onClick={() => setPage("miniProducts")}
          ></div>
          <div
            className="miniNavLink"
            id="miniContacts"
            onClick={() => setPage("miniContacts")}
          ></div>
        </div>
      </div>
      {/* Home */}
      {page === "miniHome" && (
        <div className="miniBody">
          <div className="miniSection">
            <div
              className="miniSectionTextBox"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="miniSectionTitle"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div
                className="miniSectionButton miniSectionButtonHero"
                onClick={() => setPage("miniAbout")}
              ></div>
            </div>
          </div>
        </div>
      )}

      {/* About */}
      {page === "miniAbout" && (
        <div className="miniBody">
          <div className="miniSection">
            <div className="miniSectionItem"></div>
            <div className="miniSectionTextBox">
              <div className="miniSectionTitle"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div
                className="miniSectionButton"
                onClick={() => setPage("miniHome")}
              ></div>
            </div>
          </div>
          <div className="miniSection">
            <div className="miniSectionItem"></div>
            <div className="miniSectionTextBox">
              <div className="miniSectionTitle"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div
                className="miniSectionButton"
                onClick={() => setPage("miniProducts")}
              ></div>
            </div>
          </div>
          <div className="miniSection">
            <div className="miniSectionItem"></div>
            <div className="miniSectionTextBox">
              <div className="miniSectionTitle"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div className="miniSectionDescription"></div>
              <div
                className="miniSectionButton"
                onClick={() => setPage("miniContacts")}
              ></div>
            </div>
          </div>
        </div>
      )}

      {/* Products */}
      {page === "miniProducts" && (
        <div className="miniBody">
          <div className="miniSection" style={{ paddingBottom: "20px" }}>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
            <div
              className="miniSectionItem miniSectionItemToucheble"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
              }}
              onClick={() => setPage("miniHome")}
            ></div>
          </div>
        </div>
      )}
      {/* Contacts */}
      {page === "miniContacts" && (
        <div className="miniBody">
          <div className="miniSection">
            <div
              className="miniSectionItem"
              style={{ width: "100%", height: "100px", marginRight: "0" }}
            >
              <div
                className="miniSectionButton miniContactsButton"
                onClick={() => setPage("miniHome")}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MiniSite;
